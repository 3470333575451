.button {
  display: inline-block;
  position: relative;
  padding: 0;
  min-height: 24px;
  background: var(--lightgray);
  border: solid 1px var(--lightgray);
  border-radius: 4px;
  font-size: var(--lt-small);
  color: var(--white);
  text-align: center;
  text-decoration: none !important;
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    &:hover {
      opacity: 0.3;
      cursor: default;
    }
  }

  svg.button-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 -8px;
  }

  a {
    display: block;
    border: none !important;
    color: inherit !important;
  }

  &.primary {
    background-color: var(--primary) !important;
    border: solid 1px var(--primary) !important;
    color: var(--white) !important;
  }
  &.white {
    background-color: var(--white) !important;
    border: solid 1px var(--lightgray) !important;
    color: var(--black) !important;
  }
  &.red {
    background-color: var(--red) !important;
    border: solid 1px var(--red) !important;
    color: var(--white) !important;
  }
  &.green {
    background-color: var(--green) !important;
    border: solid 1px var(--green) !important;
    color: var(--white) !important;
  }
  &.disabled {
    background-color: var(--lightgray) !important;
    border: var(--lightgray) !important;
    color: var(--white) !important;
  }
}

.card .upload-area .button::before {
  height: 14px;
  vertical-align: -2px;
  width: 14px;
}
