:root {
  /* color ------------------------------------------------------------- */
  --black: rgb(0, 0, 0);
  --darkgray: rgb(72, 72, 72);
  --gray: rgb(119, 119, 119);
  --lightgray: rgb(213, 213, 213);
  --gainsboro: rgb(220, 220, 220);
  --whitesmoke: rgb(246, 246, 246);
  --white: rgb(255, 255, 255);
  --lightgreen: rgb(201, 242, 205);
  --blue: rgb(0, 153, 204);
  --gray2: rgb(112, 112, 112);
  --gray3: rgb(242, 242, 242);

  --primary: rgb(0, 153, 204);
  --success: rgb(82, 200, 248);
  --warning: rgb(255, 242, 242);
  --danger: rgb(204, 14, 0);
  --caution: rgb(204, 14, 0);
  --caution2: rgb(204, 0, 0);
  --link: rgb(58, 49, 230);
  --hover: rgb(204, 14, 0);
  --active: rgb(228, 244, 255);
  --red: rgb(201, 71, 121);
  --green: rgb(73, 163, 81);

  /* font size --------------------------------------------------------- */
  --lt-xlarge: 228.6%; /* 32px */
  --lt-large: 171.4%; /* 24px */
  --lt-medium: 114.3%; /* 16px */
  --lt-regular: 100%; /* 14px */
  --lt-small: 85.7%; /* 12px */
  --lt-xsmall: 71.4%; /* 10px */

  /* margin & padding -------------------------------------------------- */
  --mp-xlarge: 80px;
  --mp-large: 40px;
  --mp-medium: 20px;
  --mp-regular: 10px;
  --mp-small: 5px;
  --mp-xsmall: 2px;

  /* menu size --------------------------------------------------------- */
  --menu: 200px;
}
