@import './csvUploadModal/style.scss';

.modal-window {
  position: relative;
  min-width: 480px;
  max-width: 95vw;
  min-height: 180px;
  max-height: 95vh;
  margin: 0;
  background-color: #fff;
  color: #666666;
  z-index: 9999;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 4px;
}

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  opacity: 0;
  transition: opacity 0.5s, transform 0s 0.5s;
  transform: scale(0);

  /** 一括登録時の更新確認モーダルは最前面に表示されるよう設定 */
  &:has(.confirm-dialog-container) {
    z-index: 99999;
  }

  &.show {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 16px;
  border-bottom: 1px solid #bfbfbf;

  h2 {
    svg {
      padding-right: 6px;
    }
  }

  .modal-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.modal-body {
  max-height: calc(95vh - 130px);
  padding: 16px;
  overflow-y: auto;
  overflow-x: auto;

  b {
    margin: 0 10px 0 0;
  }
  p {
    white-space: pre-wrap;
  }
  p:not(.note) {
    margin: 5px 0 0;
  }
  .note {
    color: #cc0e00;
    margin: 20px 0 0;
  }
  b.required::after {
    display: inline;
    content: '*';
    color: var(--caution);
  }

  input,
  select,
  textarea {
    background: var(--white);
    border: solid thin var(--lightgray);
    border-radius: 4px;
    color: var(--gray);
    padding: 2px 4px;

    &:focus {
      border-color: var(--primary);
      outline: 0;
    }
  }

  input[type='text'],
  select {
    height: 28px;
  }

  textarea {
    resize: none;
  }

  table {
    table-layout: fixed;
    text-align: left;

    th,
    td {
      white-space: pre;
      padding: 6px 6px;
    }

    th.required::after {
      display: inline;
      content: '*';
      color: var(--caution);
    }
  }
}

.modal-footer {
  padding: 16px;
  border-top: 1px solid #bfbfbf;
  background-color: #fafafa;

  .button-area {
    display: flex;
    text-align: center;
    justify-content: flex-end;

    .button {
      height: 32px;
      width: 100px;
      font-size: 14px;
      a {
        line-height: 30px;
      }
    }

    .button + .button {
      margin: 0 0 0 20px;
    }
  }
}
