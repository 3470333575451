.csv-upload-modal {
  font-size: 14px;

  /** 一括登録モーダルの中身 */
  .csv-upload {
    padding: 0 32px;
    color: var(--black);
    font-size: 14px;

    /** 注意文 */
    .alert {
      color: var(--danger);
    }

    /** 使い方 */
    .orders {
      margin-top: 24px;

      /** テンプレートリンク */
      .template-link {
        margin: 12px 0;

        a {
          cursor: pointer;
        }
      }

      /** CSVアップロードの1手順 */
      p {
        margin-top: 12px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .alert,
    .orders {
      line-height: 32px;
    }

    /** アップロード領域 */
    .upload-area {
      margin: 12px 0;
    }

    .file-list-header {
      margin-top: var(--mp-medium);
    }

    /** アップロードファイル詳細 */
    ul.file-list {
      max-height: 160px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: var(--mp-large);
      margin-bottom: 12px;

      li {
        margin-bottom: var(--mp-xsmall);
        list-style-type: circle;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.check {
            color: var(--danger);
          }
        }
      }
    }
  }

  .modal-footer {
    .button-area {
      .button {
        height: 2.5em;
        width: 10em;
      }
    }
  }
}
