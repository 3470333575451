.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    color: var(--color-gray);
    fill: currentColor;
  }
}

main > .loader {
  height: 95vh;
}

.contents aside.load-area {
  height: 80vh;
}
