.runtime-error {
  width: 100%;
  padding: 16px 32px;

  h3 {
    font-size: 28px;
    color: var(--caution);
  }

  p {
    margin: 12px 0;
    margin-left: 12px;
    color: var(--danger);
    white-space: pre-wrap;
  }

  .error-detail {
    padding: 4px;
    margin: 0 12px;
    border: 2px dotted var(--hover);

    .title {
      font-weight: bold;
      color: rgb(233, 72, 14);
    }

    .component-stack {
      margin-top: 4px;
      white-space: pre-wrap;
      color: rgb(233, 72, 14);
    }
  }

  .link {
    margin-top: 16px;
    margin-left: 12px;
  }
}
