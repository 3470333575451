html {
  font-size: 14px;
  height: 100%;
  line-height: 1.5;
  min-height: 100%;
  min-width: 320px;
  overflow: hidden;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
div,
figcaption,
figure,
footer,
form,
header,
nav,
ol,
p,
svg,
section,
ul {
  display: block;
  margin: 0;
  padding: 0;
  zoom: 1;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    background-attachment: initial;
    scroll-behavior: auto;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
iframe {
  margin: 0;
  padding: 0;
  border: 0;
}
a:hover,
a:active,
a:focus {
  outline: none;
  outline-width: 0;
}
::selection {
  background: var(--primary);
  color: var(--white);
}
b,
em,
strong {
  font-weight: bolder;
}
blockquote {
  margin: var(--mp-regular);
  padding: var(--mp-regular);
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  display: list-item;
  margin: 0;
  padding: 0;
}
img {
  border: 0;
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
form {
  margin: 0;
}
input {
  border-radius: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.25;
  margin: 0;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
input[type='submit'] {
  cursor: pointer;
}
button::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
}
[hidden] {
  display: none;
}
::-webkit-file-upload-button {
  font: inherit;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
th,
td {
  margin: 0;
  padding: 0;
}

body {
  background: var(--whitesmoke);
  color: var(--gray);
  font-family: 'Noto Sans JP', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1em;
  height: 100%;
}
h1,
h2,
h3,
h4,
h5 {
  color: var(--black);
  font-weight: bolder;
}
h1 {
  font-size: var(--lt-large);
}
h2 {
  font-size: var(--lt-medium);
}
h3,
h4,
h5 {
  font-size: var(--lt-regular);
}
a,
a:link,
a:visited {
  border-bottom: solid thin var(--link);
  color: var(--link);
  text-decoration: none;
}
a:hover {
  border-bottom: solid thin var(--hover);
  color: var(--hover);
  outline: 0;
}
