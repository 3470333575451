div#app {
  height: 100%;
  overflow: auto;
  word-break: break-all;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border: none;
    box-shadow: inset 0 0 1px #777;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
}

main {
  margin: 0 0 0 var(--menu);
  min-width: calc(1200px - var(--menu) - var(--mp-large));
  min-height: calc(100vh - 10px);
  overflow: hidden;
  padding: var(--mp-medium);
  width: calc(100% - var(--menu));

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

.wrap {
  position: relative;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
}

.no-scrollbar {
  overflow: hidden !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.stickybar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
}

.contents {
  &.wrap {
    flex-direction: row;
    align-items: flex-start;
  }

  aside {
    .warning {
      font-weight: bolder;

      i {
        background: var(--white);
        border-radius: 20px;
        color: var(--danger);
        font-size: var(--lt-medium);
        font-style: normal;
        margin: 0 var(--mp-small);
        padding: 0 var(--mp-regular);
      }
    }

    .tab {
      font-size: var(--lt-small);
      margin: var(--mp-large) 0 0;
      bottom: -10px;
      display: flex;
      left: 0;
      position: absolute;

      &::before {
        background: var(--white);
        bottom: -5px;
        content: '';
        display: block;
        left: 0;
        height: 5px;
        position: absolute;
        right: 0;
        width: calc(100% + 3px);
        z-index: 2;
      }

      li {
        background: var(--white);
        border-left: solid thin var(--lightgray);
        box-shadow: 1px 1px 3px var(--lightgray);
        cursor: pointer;
        padding: var(--mp-regular) var(--mp-medium);
        position: relative;

        &:first-child {
          border-left: none;
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }

        &:hover span {
          color: var(--hover);
          border-bottom: solid thin var(--hover);
        }

        &.current {
          background: var(--primary);
          cursor: default;
          position: relative;

          &::before {
            border: solid 10px transparent;
            border-top: solid 12px var(--primary);
            bottom: -18px;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            margin: 0 0 0 -10px;
            position: absolute;
            width: 0;
            z-index: 2;
          }

          span {
            color: var(--white);
            border-bottom: none;
          }
        }

        span {
          color: var(--link);
          border-bottom: solid thin var(--link);
        }
      }
    }

    .button-area {
      font-size: var(--lt-small);
      margin: 0 0 0 auto;

      a,
      label {
        cursor: pointer;
        line-height: 2.25em;
      }

      .button {
        height: 2.5em;
        width: 10em;
      }
    }
  }
}

.button-area .button {
  margin: 0 0 0 var(--mp-regular);
  font-size: 16px;
  height: 2.5em;
  width: 8em;
}

.column-2 {
  .card {
    margin: 0 0 var(--mp-medium);
    width: calc(50% - var(--mp-regular));

    &:nth-child(odd) {
      float: left;
    }

    &:nth-child(even) {
      float: right;
    }
  }
}

.card {
  background: var(--white);
  border-radius: 8px;
  box-shadow: 1px 1px 3px var(--lightgray);
  padding: var(--mp-medium) var(--mp-regular);

  table {
    border-collapse: collapse;
    &:not(.tag-operation-modal-table) {
      width: 100%;
    }
  }

  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td {
    white-space: pre;
  }

  th {
    color: var(--black);
    text-align: left;
  }

  input,
  select,
  textarea {
    background: var(--white);
    border: solid thin var(--lightgray);
    border-radius: 4px;
    color: var(--gray);
    padding: 2px 4px;

    &:focus {
      border-color: var(--primary);
      outline: 0;
    }
  }

  input[type='text'],
  input[type='date'],
  input[type='time'] {
    min-height: 28px;
  }

  input[type='file'] {
    border: none;
  }

  textarea {
    resize: none;
  }

  .error {
    input,
    select,
    textarea {
      background: var(--warning);
      border-color: var(--danger);
    }
  }

  .file-image .upload-area {
    margin: 0 0 var(--mp-medium);
  }

  .file-image h2 {
    font-size: var(--lt-regular);
    margin: 0 0 var(--mp-regular);
  }

  .file-document,
  .file-image {
    .upload-area .note {
      font-size: var(--lt-small);
      margin: var(--mp-small) 0 0;
      white-space: pre-line;
    }
  }

  .file-document,
  .file-image,
  .thumbnail {
    .button-area {
      margin: var(--mp-regular) 0 var(--mp-medium);
      .button {
        font-size: 12px;
        height: 24px;
        margin: 0 0 0 var(--mp-regular);
        width: 80px;
      }
    }
  }

  .upload-area {
    .file-upload-button {
      height: 28px;
      line-height: 28px;
      width: 10em;
      text-align: left;

      .file-upload-button-icon {
        width: 14px;
        height: 14px;
        margin-left: 10px;
      }
    }
  }

  .image-area {
    .image-main {
      position: relative;
      height: 300px;
      overflow: hidden;
      text-align: center;
      cursor: pointer;
    }

    .thumbnail.wrap {
      justify-content: flex-start;
      gap: 0 10px;
    }

    .thumbnail li {
      position: relative;
      margin: var(--mp-regular) 0 0;
      overflow: hidden;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;

      .play-icon {
        position: absolute;
        top: 20%;
        left: 20%;
        color: var(--white);
        opacity: 0.9;
      }

      .button-area {
        .button-icon {
          width: 14px;
          height: 14px;
        }
      }
    }

    .upload-area {
      ul {
        padding-left: 1.5em;
      }
      li {
        padding-left: 0.5em;
        list-style-type: circle;
      }
      .button {
        &._disabled {
          opacity: 0.3;
        }
        &._disabled:hover {
          cursor: default;
        }
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      width: 60%;
      height: 60%;
      object-fit: contain;
    }
  }
}

.card:not(.detail) {
  table.table {
    table-layout: fixed;
    height: 100%;
    width: 100%;
    user-select: none;

    tr {
      border-bottom: solid thin var(--whitesmoke);

      &:hover {
        background-color: rgba(0, 153, 204, 0.04) !important;
      }
      // テーブルチェックボックスON時に行の背景色変更を強制
      &.checked-row {
        background-color: #0099cc0a !important;
      }
      /** 未使用設備(.is-unused) の行はhoverしても light-gray color を維持する */
      &.checked-row:hover:not(.is-unused) {
        background-color: #0099cc0a !important;
      }

      &:last-child {
        border: none;
      }

      &.tr-loader .loader {
        height: 100%;
      }

      &.tr-loader,
      &.no-data {
        &:hover {
          background: transparent !important;
        }
      }
    }

    thead {
      tr:hover {
        background-color: transparent !important;
      }

      th {
        position: relative;
        border-bottom: solid thin var(--lightgray);
        color: var(--black);
        padding: 0 0 var(--mp-regular) var(--mp-regular);
        cursor: pointer;

        span {
          padding-bottom: 2px;
        }

        .icon-button.sort {
          visibility: hidden;
          position: absolute;
          right: 0px;
          svg {
            position: relative;
            top: 2px;
          }
        }
      }

      th.current {
        span {
          border-bottom: 2px solid var(--success);
        }

        .icon-button {
          visibility: visible;
          svg {
            color: var(--black);
          }
        }
      }

      th.disabled {
        cursor: default;
      }

      th:not(.current):not(.disabled):hover {
        color: var(--gray);
        .icon-button {
          visibility: visible;
          svg {
            color: var(--gray);
          }
        }
      }
    }

    tbody {
      td {
        padding: var(--mp-small) 0 var(--mp-small) var(--mp-regular);
      }

      tr {
        cursor: pointer;
      }

      a:not(:hover) {
        border: none;
      }
    }
  }

  table.table:not(.simple) tbody tr:nth-child(even) {
    background-color: rgba(252, 252, 252, 1);
  }

  .table-wrapper {
    width: 100%;
    // overflow-x: hidden;
    overflow-x: auto; /* 横方向のオーバーフローにスクロールバーを追加 */
    overflow-y: scroll;
  }
}

.card.detail {
  table.table {
    table-layout: fixed;
    height: 100%;
    width: 100%;
    user-select: none;

    tr {
      border-top: dotted thin var(--lightgray);

      &:first-child {
        border-top: none;
        th {
          padding: var(--mp-xsmall) 0 var(--mp-regular);
        }
        td {
          padding: var(--mp-xsmall) var(--mp-regular) var(--mp-regular) var(--mp-regular);
        }
      }
      &:last-child {
        th {
          padding: var(--mp-regular) 0 var(--mp-xsmall);
        }
        td {
          padding: var(--mp-regular) var(--mp-regular) var(--mp-xsmall) var(--mp-regular);
        }
      }
    }
    th {
      padding: var(--mp-regular) 0;
    }
    td {
      padding: var(--mp-regular) 0 var(--mp-regular) var(--mp-regular);
    }
  }
}

.priority-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding-bottom: 2px;
  border-radius: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: var(--white);

  &.priority-icon--high {
    background: #e71e07;
  }
  &.priority-icon--middle {
    background: #ffba30;
  }
  &.priority-icon--low {
    background: #b2d43b;
  }
}

.list {
  .contents {
    margin: var(--mp-medium) 0 0;

    aside .button-area {
      font-size: 18px;
    }

    aside.load-area {
      height: calc(100vh - 140px);
      min-height: 300px;
    }
  }

  .card {
    align-items: flex-start;
    margin: var(--mp-regular) 0 0;

    &.no-data table tr:hover {
      background-color: transparent !important;
    }

    table:not(.table) {
      tr {
        border-bottom: solid thin var(--whitesmoke);

        &:hover {
          background-color: var(--whitesmoke);
        }

        &:first-child:hover {
          background-color: transparent;
        }

        &:nth-child(2) td {
          padding: var(--mp-regular) 0 var(--mp-small) var(--mp-regular);
        }

        &:last-child {
          border: none;
        }
      }

      tr.tr-loader {
        .loader {
          height: 469px; // magic number
        }
        &:hover {
          background: transparent;
        }
      }

      th {
        border-bottom: solid thin var(--lightgray);
        color: var(--black);
        padding: 0 0 var(--mp-regular) var(--mp-regular);
      }

      td {
        padding: var(--mp-small) 0 var(--mp-small) var(--mp-regular);
      }
    }
  }
}

.specific {
  .contents {
    margin: var(--mp-medium) 0 0;

    aside .button-area {
      height: 40px;
    }

    aside.load-area {
      height: calc(100vh - 140px);
      min-height: 300px;
    }
  }

  .card {
    align-items: flex-start;
    margin: var(--mp-regular) 0 0;

    table:not(.table) {
      tr {
        border-top: dotted thin var(--lightgray);

        &:first-child {
          border-top: none;
          th {
            padding: var(--mp-xsmall) 0 var(--mp-regular);
          }
          td {
            padding: var(--mp-xsmall) var(--mp-regular) var(--mp-regular) var(--mp-regular);
          }
        }

        &:last-child {
          th {
            padding: var(--mp-regular) 0 var(--mp-xsmall);
          }
          td {
            padding: var(--mp-regular) var(--mp-regular) var(--mp-xsmall) var(--mp-regular);
          }
        }
      }

      th {
        padding: var(--mp-regular) 0;
      }

      td {
        padding: var(--mp-regular) 0 var(--mp-regular) var(--mp-regular);
      }
    }
  }

  .card.list {
    align-items: flex-start;
    margin: var(--mp-regular) 0 0;

    &.no-data table tr:hover {
      background-color: transparent;
    }

    table {
      tr {
        border-bottom: solid thin var(--whitesmoke);
        cursor: pointer;

        &:hover {
          background-color: var(--whitesmoke);
        }

        &:first-child {
          cursor: default;
        }

        &:first-child:hover {
          background-color: transparent;
        }

        &:nth-child(2) td {
          padding: var(--mp-regular) 0 var(--mp-small) var(--mp-regular);
        }

        &:last-child {
          border: none;
        }
      }

      th {
        border-bottom: solid thin var(--lightgray);
        color: var(--black);
        padding: 0 0 var(--mp-regular) var(--mp-regular);
      }

      td {
        padding: var(--mp-small) 0 var(--mp-small) var(--mp-regular);
      }
    }
  }

  .file-document li {
    margin: 0 0 10px;
  }
}

.edit.specific {
  table tbody {
    th.required::after {
      display: inline;
      content: '*';
      color: var(--caution);
    }

    td .message {
      white-space: pre-wrap;
    }
  }
}
